import React, { useEffect } from 'react';

function DavidJonesPromotionPage({ location }) {
  useEffect(() => {
    if (window?.location) {
      window.location.replace('https://cloud.cards.davidjones.latitudefinancial.com.au/djs-trip-to-paris');
    }
  }, []);

  return <></>;
}

export default DavidJonesPromotionPage;  
